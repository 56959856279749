<template>
  <div id="app">
    <!-- 展示路由内容 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  created() {
    // console.log(window.location.pathname);
    // const apiKey = "c9be17649f0d4072920268c2f31e5442";
    // fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${apiKey}`)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (
    //       !(
    //         data.country_code2 === "CN" ||
    //         data.country_code2 === "HK" ||
    //         data.country_code2 === "MO" ||
    //         data.country_code2 === "TW"
    //       )
    //     ) {
    //       if (!window.location.pathname.includes("en")) {
    //         if (window.location.pathname == "/") {
    //           this.$router.push("/en");
    //         } else {
    //           let insert = "/en" + window.location.pathname;
    //           this.$router.push(insert);
    //         }
    //       }
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
  },
  mounted() {
  },
};
</script>

<style lang="scss">
@import url("./assets/css/font.css");
@media screen and (max-width: 1100px) {
  .web-title {
    font-size: 28px;
  }
}

@media screen and (min-width: 1100px) {
  .web-title {
    font-size: 30px;
  }
}

@media screen and (max-width: 960px) {
  .web-title {
    margin: 30px auto 50px !important;
  }

  .contrast {
    grid-template-columns: repeat(1, 1fr);
  }

  .purchase-session {
    img {
      width: 140px;
    }

    .btns {
      margin-top: 30px;

      button {
        width: 135px;
        height: 38px;
        border-radius: 23px;
        font-size: 19px;
        margin: 0 25px;
      }
    }
  }
}

@media screen and (min-width: 960px) {
  .web-title {
    margin: 87px auto;
  }

  .contrast {
    grid-template-columns: repeat(3, 1fr);
  }

  .purchase-session {
    img {
      width: 170px;
    }

    .btns {
      margin-top: 40px;

      button {
        width: 160px;
        height: 46px;
        border-radius: 23px;
        font-size: 20px;
        margin: 0 40px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .web-title {
    font-size: 24px;
  }
  .core-session {
    padding: 40px 30px;
  }
  .purchase-session {
    .btns {
      button {
        width: 115px;
        height: 34px;
        border-radius: 17px;
        font-size: 16px;
        margin: 0 15px;
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .core-session {
    padding: 80px 60px;
  }
}

@keyframes bottomMoveTop {
  0% {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

:root {
  --main-one-color: #1d61e5;
  --main-two-color: #3461dc;
  --main-three-color: #5389f2;
  --main-bg-color: #010c21;
}

.queue-bottom {
  animation: bottomMoveTop 0.6s cubic-bezier(0.5, 1, 0.89, 1);
  animation-fill-mode: forwards;
}

.web-title {
  max-width: 1500px;
  position: relative;
  font-family: "Alibaba PuHuiTi B", sans-serif;
  color: var(--main-bg-color);
  text-align: center;
  transition: all 0.5s ease;

  &::after {
    content: "";
    width: 31px;
    border: 2px solid var(--main-two-color);
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
  }
}

// 核心参数
.core-session {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f9ff;
  transition: all 0.3s ease;
}

.core-pack {
  width: 100%;
  max-width: 1200px;
}

.contrast {
  display: grid;
  // grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  transition: all 0.3s ease;
}

.contrast-item {
  border-radius: 4px;
  padding: 20px;
  background: linear-gradient(135deg, #eef3fd 0%, #e7ebf4 82%);

  .argu-cell:not(:last-child) {
    border-bottom: 1px solid #d5ddec;
  }
  &:hover {
    background: linear-gradient(135deg, #d1e1f8 0%, #d4def2 82%);
    .argu-cell:not(:last-child) {
      border-bottom: 1px solid #bcc5d7;
    }
  }

  .pro {
    text-align: center;
    margin: 25px 0;

    img {
      width: 169px;
      height: auto;
      margin-bottom: 5px;
    }

    p {
      font-family: "Poppins-Medium", sans-serif;
      font-size: 24px;
      color: #010c21;
    }
  }
}

.argu-cell {
  padding: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  .item {
    width: 49%;
    color: var(--main-bg-color);

    .key {
      font-size: 17px;
      opacity: 0.5;
      margin-bottom: 5px;
    }

    .value {
      width: 100%;
      font-family: "Poppins-Regular", sans-serif;
      font-size: 18px;

      .it {
        word-wrap: break-word;
        span {
          font-size: 16px;
          display: inline-block;
        }
      }
    }

    .scan {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .it {
        min-width: 50px;
      }
    }
  }

  .item-odd {
    width: 100%;

    .value {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .it {
        margin-right: 20px;
      }
    }
  }
}

.purchase-session {
  text-align: center;

  img {
    width: 170px;
    height: auto;
  }

  .btns {
    button {
      border: none;
      background: linear-gradient(
        0deg,
        rgba(136, 150, 178, 0.4) 0%,
        rgba(185, 196, 219, 0.4) 100%
      );
      color: var(--main-bg-color);
      cursor: pointer;

      &:hover {
        background: linear-gradient(
          0deg,
          var(--main-one-color) 0%,
          var(--main-three-color) 100%
        );
        color: #ffffff;
      }
    }
  }
}
</style>
